import { MiddlewareFactory, spatial } from '@canalplus/one-navigation';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  MIDDLEWARE_CENTER,
  MIDDLEWARE_STRATE_TOP,
} from '../../../../helpers/oneNavigation/middleware';
import { centerBinder, scroll } from '../../../../helpers/oneNavigation/scroll';
import { isHeaderActiveOnTVSelector } from '../../../../selectors/header-selectors';
import { featDisplayHeaderMenuSelector } from '../../../../store/slices/application-selectors';
import { PromotionStateTypes } from '../../../../templates/LandingV5/components/PromotionStrate/types';

function getMiddlewareForPromotionStrate(
  isCover: boolean,
  parentElement: HTMLElement | null
): MiddlewareFactory[] {
  if (isCover) {
    return MIDDLEWARE_STRATE_TOP;
  }

  // It will scroll by using parent html element bounds instead of binder himself
  if (parentElement) {
    return [scroll([() => centerBinder(parentElement)]), spatial()];
  }

  return MIDDLEWARE_CENTER;
}

export const usePromotionStrateBinderProps = (
  promotionType: PromotionStateTypes
): {
  middleware: MiddlewareFactory[];
  forceFocusOnMount: boolean;
  setPromotionStrateRef: (value: HTMLDivElement) => void;
} => {
  const isCover = promotionType === PromotionStateTypes.cover;
  const defaultMiddleware = isCover ? MIDDLEWARE_STRATE_TOP : MIDDLEWARE_CENTER;

  const [promotionStrateRef, setPromotionStrateRef] =
    useState<HTMLDivElement | null>(null);
  const [middleware, setMiddleware] = useState(defaultMiddleware);

  const featDisplayHeaderMenu = useSelector(featDisplayHeaderMenuSelector);
  const isHeaderActiveOnTV = useSelector(isHeaderActiveOnTVSelector);

  // force focus on mount when the promotion strate is cover and no header menu on this page
  const forceFocusOnMount =
    (!featDisplayHeaderMenu || !isHeaderActiveOnTV) && isCover;

  // keep using useEffect/useState because we have to wait the instanciation of parentElementRef ref on HTML element
  useEffect(() => {
    setMiddleware(getMiddlewareForPromotionStrate(isCover, promotionStrateRef));
  }, [isCover, promotionStrateRef]);

  return { middleware, forceFocusOnMount, setPromotionStrateRef };
};
