import { useMemo } from 'react';
import { Video } from '../../../../../../components/Video';
import { ScaleUp } from '../../../../../../components/Video/components/PlayerContainer/PlayerContainer';
import { useAreAnimationsMuted } from '../../../../../../components/Video/hooks/useAreAnimationsMuted';
import { useIntersectionObserverForVideo } from '../../../../../../components/Video/hooks/useIntersectionObserverForVideo';
import { VideoContent } from '../../../../../../components/Video/types';
import Cover from '../../../../../../templates/LandingV5/components/PromotionStrate/components/Cover/Cover';
import { PromotionStateTypes } from '../../../../../../templates/LandingV5/components/PromotionStrate/types';
import { PromotionVideoContent } from '../../data/types';
import { useShortVideoIds } from '../../useShortVideoIds';
import styles from './MediaLayer.css';

const TIME_BETWEEN_COVERS_AND_PLAYER = 2000; // Must not be less than 2000ms (value of css animations)
const THRESHOLD_WEB = 0.5;
const THRESHOLD_TV_COVER = 0.9;
const THRESHOLD_TV_BANNER = 0.99; // Supposedly 100% but IntersectionObserver is not reliable, and sometimes it doesn't detect 100%, but only 99%

type VideoData = {
  isLoop: boolean;
  videoContent: VideoContent;
};

const getVideoData = ({
  hasVideoBeenFullyWatched,
  video,
  videoOutro,
}: {
  hasVideoBeenFullyWatched: boolean;
  video?: PromotionVideoContent;
  videoOutro?: PromotionVideoContent;
}): VideoData | undefined => {
  if (video?.url && !hasVideoBeenFullyWatched) {
    return {
      isLoop: false,
      videoContent: {
        encryption: 'clear',
        url: video.url,
      },
    };
  }

  if (videoOutro?.url) {
    return {
      isLoop: true,
      videoContent: {
        encryption: 'clear',
        url: videoOutro.url,
      },
    };
  }

  return;
};

export type MediaLayerProps = {
  /**
   * The alt of the img
   */
  alt?: string;
  /**
   * If false, only a cover will be displayed
   */
  areAnimationsAutoplay: boolean;
  /**
   * If true, videoOutro will be played and without videoOutro only the cover will be displayed
   */
  hasVideoBeenFullyWatched: boolean;
  /**
   * If false, the Cover and the </video> will be scaled up on desktop only
   */
  isPhoneResolution?: boolean;
  /**
   * If true, the height of Cover will be higher
   */
  isTvDevice?: boolean;
  /**
   * The type of the promotion strate: cover or banner
   */
  promotionType: PromotionStateTypes;
  /**
   * Used as a uniq id to play the default video only once, until the next refresh of the app
   */
  uniqId: string;
  /**
   * The image displayed before the video for mobile
   */
  URLImageCompact?: string;
  /**
   * The image displayed before the video for other dives than mobile
   */
  URLImageRegular: string;
  /**
   * The contentId or the url (if no DRM) of the content needed by OnePlayer
   */
  video?: PromotionVideoContent;
  /**
   * The contentId or the url (if no DRM) of the content of videoOutro needed by OnePlayer
   */
  videoOutro?: PromotionVideoContent;
};

function MediaLayer({
  alt,
  areAnimationsAutoplay,
  hasVideoBeenFullyWatched,
  isPhoneResolution = true,
  isTvDevice,
  promotionType,
  uniqId,
  URLImageCompact,
  URLImageRegular,
  video,
  videoOutro,
}: MediaLayerProps): JSX.Element {
  const { areAnimationsMuted } = useAreAnimationsMuted();
  const isCover = promotionType === PromotionStateTypes.cover;
  const thresholdTV = isCover ? THRESHOLD_TV_COVER : THRESHOLD_TV_BANNER;
  const intersectionThreshold = isTvDevice ? thresholdTV : THRESHOLD_WEB;
  const hasScaleUp = !isPhoneResolution && !isTvDevice && isCover;
  const { addInShortVideoIds } = useShortVideoIds(); // To manage only played once
  const videoData = useMemo(
    () => getVideoData({ hasVideoBeenFullyWatched, video, videoOutro }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [video, videoOutro]
  ); // Do not add dependencies to avoid rerending Video if hasVideoBeenFullyWatched changes from false to true

  // instantly at the end of the video and before the ending animations
  const handleNaturalEnding = () => {
    addInShortVideoIds(uniqId); // To manage only played once
  };
  const { intersectionRef, isRootRefIntersecting } =
    useIntersectionObserverForVideo(intersectionThreshold);

  const cover = (
    <Cover
      alt={alt}
      isTvDevice={isTvDevice}
      promotionType={promotionType}
      URLImageRegular={URLImageRegular}
      URLImageCompact={URLImageCompact}
    />
  );

  return (
    <div className={styles.mediaLayer} ref={intersectionRef}>
      {areAnimationsAutoplay && videoData ? (
        <Video
          cover={cover}
          isLoop={videoData?.isLoop}
          isMuted={areAnimationsMuted}
          onNaturalEnding={handleNaturalEnding}
          scaleUp={hasScaleUp ? ScaleUp.Small : undefined}
          shouldDestroyPlayerAtTheEnd={true}
          timeBetweenCoversAndPlayer={TIME_BETWEEN_COVERS_AND_PLAYER}
          videoContent={videoData?.videoContent}
          isVisible={isRootRefIntersecting}
        />
      ) : (
        cover
      )}
    </div>
  );
}

export default MediaLayer;
