import { Template } from '@canalplus/sdk-hodor';
import { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import { useSelector } from 'react-redux';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FromProp } from '../../../server/modules/fetchWithQuery/types';
import { FetchDetails, FetchRequestTypes } from '../../../services/types';
import {
  featIdentityV5Selector,
  isIOsSelector,
} from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import {
  LandingV5State,
  getFormattedLandingUrl,
} from '../../../templates/LandingV5/data/formatter';
import { LandingSearchProvider } from './LandingSearch/store/context';
import LandingV5 from './LandingV5';

export type LandingV5ContainerProps = {
  disableMetaUpdate?: boolean;
  enableAlternateLinksUpdate?: boolean;
  onClickParameters?: FetchDetails['onClickParameters'];
  onFocusable?: () => void;
  url?: string;
} & FromProp;

function LandingV5Container({
  disableMetaUpdate = false,
  enableAlternateLinksUpdate = true,
  from,
  onClickParameters,
  onFocusable,
  url,
}: LandingV5ContainerProps): JSX.Element | null {
  const isIOS = useSelector(isIOsSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const urlFormatted = url
    ? getFormattedLandingUrl(url, isTvDevice)
    : undefined;
  const featIdentityV5 = useSelector(featIdentityV5Selector);

  const [{ isLoading, data, error }] = useQueryTemplate<
    LandingV5State & { tracking: Tracking }
  >(
    urlFormatted,
    {
      disableMetaUpdate,
      enableAlternateLinksUpdate,
      from,
      onClickParameters,
      template: FetchRequestTypes.LandingV5,
    },
    {
      refetchOnMount: false,
      enabled: !!urlFormatted,
    },
    { featIdentityV5, isIOS }
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (error || !urlFormatted) {
    return <ErrorTemplate error={error} />;
  }

  if (!data) {
    return null;
  }

  const isEPGStrateExist = !!data.strates?.some(
    (strate) => strate.type === Template.ElectronicProgramGuide
  );
  const isSearchEnabled = isEPGStrateExist;

  const LandingV5Component = (
    <LandingV5
      data={data}
      isSearchEnabled={isSearchEnabled}
      from={from}
      onFocusable={onFocusable}
    />
  );

  return isSearchEnabled ? (
    <LandingSearchProvider>{LandingV5Component}</LandingSearchProvider>
  ) : (
    LandingV5Component
  );
}

export default LandingV5Container;
