import { ApiV2PageStratePromotion } from '@dce-front/hodor-types/api/v2/page/dtos/strates/promotion/definitions';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { VideoProvider } from '../../../../components/Video';
import { useAreAnimationsAutoplay } from '../../../../components/Video/hooks/useAreAnimationsAutoplay';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { isPhoneResolutionSelector } from '../../../../store/slices/ui-selectors';
import InfoLayer from '../../../../templates/LandingV5/components/PromotionStrate/components/InfoLayer/InfoLayer';
import MediaLayer from '../../../../templates/LandingV5/components/PromotionStrate/components/MediaLayer/MediaLayer';
import { PromotionStrateContentData } from '../../../../templates/LandingV5/components/PromotionStrate/data/types';
import { PromotionStateTypes } from '../../../../templates/LandingV5/components/PromotionStrate/types';
import styles from './PromotionStrate.css';
import { usePromotionStrateBinderProps } from './usePromotionStrateBinderProps';
import { useShortVideoIds } from './useShortVideoIds';

export type PromotionStrateProps = Pick<
  ApiV2PageStratePromotion,
  'displayParameters'
> & {
  /**
   * Data of the strate
   */
  content: PromotionStrateContentData;
  /**
   * The type of the promotion strate: cover or banner
   */
  promotionType: PromotionStateTypes;
};
function PromotionStrate({
  content,
  promotionType,
}: PromotionStrateProps): JSX.Element {
  const { areAnimationsAutoplay } = useAreAnimationsAutoplay();
  const { isInShortVideoIds } = useShortVideoIds();

  const {
    altLogoChannel,
    altLogoType,
    altText,
    button,
    catchlines,
    contentID,
    isOutroOnly,
    media,
    URLLogoChannel,
    URLLogoType,
  } = content;
  const isTvDevice = useSelector(displayTVModeSelector);
  const hasVideoBeenFullyWatched = isInShortVideoIds(contentID);
  const isPhoneResolution = useSelector(isPhoneResolutionSelector);
  const { compact, regular } = media;
  const video = isPhoneResolution
    ? compact?.video || regular?.video
    : regular?.video;
  const videoOutro = isPhoneResolution
    ? compact?.videoOutro || regular?.videoOutro
    : regular?.videoOutro;
  const canInfoLayerDisplayAllElem = isOutroOnly
    ? !areAnimationsAutoplay || hasVideoBeenFullyWatched
    : true;
  const { middleware, forceFocusOnMount, setPromotionStrateRef } =
    usePromotionStrateBinderProps(promotionType);

  return (
    <div
      className={classNames(styles.promotionStrate, {
        [styles['promotionStrate--banner']]:
          promotionType === PromotionStateTypes.banner,
        [styles['promotionStrate--cover']]:
          promotionType === PromotionStateTypes.cover,
      })}
      ref={setPromotionStrateRef}
    >
      <VideoProvider>
        <MediaLayer
          alt={altText}
          areAnimationsAutoplay={areAnimationsAutoplay}
          hasVideoBeenFullyWatched={hasVideoBeenFullyWatched}
          isPhoneResolution={isPhoneResolution}
          isTvDevice={isTvDevice}
          promotionType={promotionType}
          uniqId={contentID}
          URLImageCompact={compact?.URLImageDefault}
          URLImageRegular={regular.URLImageDefault}
          video={video}
          videoOutro={videoOutro}
        />
        <div>
          <InfoLayer
            altLogoChannel={altLogoChannel}
            altLogoType={altLogoType}
            button={button}
            canDisplayAllElem={canInfoLayerDisplayAllElem}
            catchlines={catchlines}
            forceFocusOnMount={forceFocusOnMount}
            middleware={middleware}
            URLLogoChannel={URLLogoChannel}
            URLLogoType={URLLogoType}
          />
        </div>
      </VideoProvider>
    </div>
  );
}

export default PromotionStrate;
