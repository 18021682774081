import type { ContentRowHeader } from '@canalplus/mycanal-sharedcomponent';
import { Template, TitleDisplayMode } from '@canalplus/sdk-hodor';
import { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import classNames from 'classnames/bind';
import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LoadableBanner from '../../../../components/Banner/LoadableBanner';
import LoadableCarrouselTemplate from '../../../../components/Carrousel/LoadableCarrouselTemplate';
import LoadablePlainTextHtmlTemplate from '../../../../components/PlainTextHtml/LoadablePlainTextHtmlTemplate';
import SkipLink from '../../../../components/SkipLink/SkipLink';
import { StrateMode } from '../../../../constants/strates';
import {
  MIDDLEWARE_STRATE_PERSO_TOP,
  MIDDLEWARE_STRATE_TOP,
} from '../../../../helpers/oneNavigation/middleware';
import I18n from '../../../../lang';
import { FromProp } from '../../../../server/modules/fetchWithQuery/types';
import { FetchDetails } from '../../../../services/types';
import {
  getFeatureTogglePromotionBanner,
  isIOsSelector,
} from '../../../../store/slices/application-selectors';
import LoadableContentGrid from '../../../ContentGrid';
import LoadableContentGridPerso from '../../../ContentGridPerso';
import LoadableContentRowLiveTv from '../../../ContentRowLive';
import LoadableContentRowPerso from '../../../ContentRowPerso';
import LoadableContentRowStandard from '../../../ContentRowStandard';
import LoadableElectronicProgramGuide from '../../../ElectronicProgramGuide';
import { StrateV5 } from '../../data/formatter';
import PromotionStrate from '../PromotionStrate/PromotionStrate';
import getFormattedPromotionStrate from '../PromotionStrate/data/getFormattedPromotionStrate';
import styles from './Strate.css';

const cx = classNames.bind(styles);

export const STRATE_TYPES_WITHOUT_SKIP_LINK = [
  'banner',
  'promotionBanner',
  'plainTextHTML',
] as Template[] satisfies Template[];

export type StrateProps = {
  /**
   * Whether current strate is one in a series of Strate items.
   *
   * When `true`, a skip link to the next strate is displayed.
   */
  hasNextStrate: boolean;
  /** The anchor id of the Strate to navigate to.  */
  hrefNextStrate?: string;
  /** The unique identifier of the strate. */
  id: string;
  /** Whether the strate is the first one in a series of Strate items. */
  isFirstStrate: boolean;
  /** Whether the device is a TV device. */
  isTvDevice: boolean;
  /** Whether the strate is display within a detail page. */
  isFromDetail: boolean;
  /** Object containing the Strate data keys. */
  strate: StrateV5;
  /** Callback triggered when Strate is focused. */
  onFocusable?: () => void;
  /** Page tracking object. */
  tracking: ApiV2PageTracking;
  /** Data test id. */
  dataTestId?: string;
} & FromProp;

/**
 * Displays a horizontal strate of content (Carousel, ContentRow, etc.),
 * with an optional skip link to move past blocks of strate contents when using the keyboard.
 */
function Strate({
  id,
  from,
  hasNextStrate = false,
  hrefNextStrate,
  isFirstStrate,
  isTvDevice = false,
  isFromDetail,
  strate: {
    button,
    contents,
    context,
    displayParameters,
    paging,
    perso,
    strateMode,
    subtitle,
    title,
    type,
    URLProgramGuide,
    css,
    html,
    js,
  },
  tracking,
  onFocusable,
  dataTestId,
}: StrateProps): JSX.Element | null {
  const { t } = I18n.useTranslation();
  const isIOS = useSelector(isIOsSelector);

  const { URLPage, parameters: onClickParameters } = paging || {};
  const { anchorIndex: itemOffset, titleDisplayMode } = displayParameters || {};

  const header = useMemo<ContentRowHeader>(
    () => ({ title, subtitle, ...(!isTvDevice && { button }) }),
    [isTvDevice, title, subtitle, button]
  );

  const hasPromotionBanner = useSelector(getFeatureTogglePromotionBanner);

  const strateContentProps = {
    displayParameters,
    from,
    header,
    isFromDetail,
    onClickParameters,
    onFocusable,
  } satisfies Partial<StrateProps> & {
    header: ContentRowHeader;
    displayParameters: StrateV5['displayParameters'];
    onClickParameters?: FetchDetails['onClickParameters'];
  };

  const getStrateContent = () => {
    switch (type) {
      case Template.Banner:
        return contents ? (
          <LoadableBanner contents={contents} onFocusable={onFocusable} />
        ) : null;

      case Template.Carrousel:
        return contents ? (
          <LoadableCarrouselTemplate
            contents={contents}
            displayParameters={displayParameters}
            onFocusable={onFocusable}
          />
        ) : null;

      case Template.ContentGrid:
        if (strateMode === StrateMode.Perso && URLPage) {
          return (
            <LoadableContentGridPerso
              {...strateContentProps}
              persoType={perso}
              url={URLPage}
            />
          );
        }

        return contents ? (
          <LoadableContentGrid
            {...strateContentProps}
            contents={contents}
            paging={paging}
            url={URLPage}
          />
        ) : null;

      case Template.ContentRow: {
        // Enable scroll to top middleware on first strate, when landing is not inside a detailV5 page
        const showMiddlewareWithScrollToTop = isFirstStrate && !isFromDetail;

        if (strateMode === StrateMode.Perso) {
          return URLPage ? (
            <LoadableContentRowPerso
              {...strateContentProps}
              tracking={tracking}
              trackingContext={context}
              url={URLPage}
              {...(showMiddlewareWithScrollToTop && {
                middleware: MIDDLEWARE_STRATE_PERSO_TOP,
              })}
            />
          ) : null;
        } else if (strateMode === StrateMode.LiveTv) {
          return URLPage ? (
            <LoadableContentRowLiveTv
              {...strateContentProps}
              itemOffset={itemOffset}
              url={URLPage}
              trackingContext={context}
              {...(showMiddlewareWithScrollToTop && {
                middleware: MIDDLEWARE_STRATE_TOP,
              })}
            />
          ) : null;
        } else {
          return contents ? (
            <LoadableContentRowStandard
              {...strateContentProps}
              contents={contents}
              itemOffset={itemOffset}
              URLNextPage={URLPage}
              {...(showMiddlewareWithScrollToTop && {
                middleware: MIDDLEWARE_STRATE_TOP,
              })}
            />
          ) : null;
        }
      }

      case Template.ElectronicProgramGuide:
        return URLProgramGuide ? (
          <LoadableElectronicProgramGuide url={URLProgramGuide} from={from} />
        ) : null;

      case Template.PlainTextHtml:
        return (
          <LoadablePlainTextHtmlTemplate
            title={title}
            html={html}
            style={css}
            script={js}
          />
        );

      case Template.PromotionBanner: {
        if (!hasPromotionBanner) {
          return null;
        }
        const formattedContent = getFormattedPromotionStrate(
          {
            displayParameters,
            contents,
            type,
          },
          isIOS
        );

        if (!formattedContent) {
          return null;
        }

        return (
          <PromotionStrate
            content={formattedContent.content}
            promotionType={formattedContent?.promotionType}
          />
        );
      }

      default:
        return null;
    }
  };

  const strateContent = getStrateContent();

  if (!strateContent) {
    return null;
  }

  const isStrateSkipLinkCompatible =
    !!type && !STRATE_TYPES_WITHOUT_SKIP_LINK.includes(type as Template);
  const showSkipLink =
    !isFromDetail &&
    !isTvDevice &&
    isStrateSkipLinkCompatible &&
    (isFirstStrate ? hasNextStrate : true);

  return (
    <div
      className={cx('strate', {
        'strate--no-padding': titleDisplayMode === TitleDisplayMode.All,
      })}
      id={id}
      data-testid={dataTestId}
    >
      {showSkipLink && (
        <SkipLink
          href={hrefNextStrate}
          label={hasNextStrate ? 'nextCategory' : 'lastCategory'}
          ariaLabel={
            title
              ? t('A11y.nextCategoryAriaLabel', { category: title })
              : undefined
          }
          className={cx('strate__skip-link')}
        />
      )}
      {strateContent}
    </div>
  );
}

export default memo(Strate);
